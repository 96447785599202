<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid class="overflow-y-auto">
        <div class="flex flex-col mx-auto w-1/3">
            <p class="headline text-center grey--text mt-6">
              Welcome to <strong>EasyNote</strong>
            </p>
            <v-card class="elevation-2">
              <p class="text-gray-400 font-bold text-center my-2 text-lg">Login Form</p>
              <v-card-text>
                <v-form class="flex flex-col space-y-2">
                  <v-text-field
                    prepend-icon="mdi-email"
                    name="email"
                    label="Email"
                    type="text"
                    dense
                    clearable
                    rounded
                    filled
                    hide-details
                    v-model="form.email"
                    @keydown.enter="login">
                  </v-text-field>
                  <v-text-field
                    id="password"
                    prepend-icon="mdi-lock"
                    name="password"
                    label="Password"
                    type="password"
                    dense
                    clearable
                    rounded
                    filled
                    hide-details
                    v-model="form.password"
                    @keydown.enter="login">
                  </v-text-field>
                </v-form>
                <p v-if="error" class="red--text">{{ error }}</p>
              </v-card-text>
              <v-card-text class="pt-0">
                Forgot password? 
                <router-link :to="{ path: '/resetpassword' }">Reset</router-link>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn block color="primary" @click="login">Login</v-btn>
              </v-card-actions>
              <v-card-text class="pt-0">
                Not registered user yet? 
                <router-link :to="{ path: '/signup', query: $route.query }">Sign Up</router-link>
              </v-card-text>
            </v-card>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'Login',

  data() {
    return {
      form: {
        email: 'koder2.chen.1984@gmail.com',
        password: 'abc123'
      },
      ref: null,
      error: null
    };
  },
  
  methods: {
    async login() {
      try {
        const { email, password } = this.form
        await this.$Parse.User.logIn(email, password)
        let user = this.$Parse.User.current()
        window.localStorage.setItem('token', user.getSessionToken())
        this.redirect()
      } catch(err) {
        console.error(err);
        this.error = `${err.message}: Wrong email address or wrong password.`;
      }
    },

    async loginWithToken() {
      try {
        let token = window.localStorage.getItem('token')
        console.log(token)
        if (token) {
          await this.$Parse.User.become(token)
          this.redirect()
        }
      } catch(error) {
        console.error(error)
      }
    },

    redirect() {
      if (this.ref) {
        this.$router.push({ name: this.ref })
      } else {
        this.$router.push({ name: 'user-notes' })
      }
    }
  },

  async mounted() {
    const { email, ref } = this.$route.query;
    if (email) {
      this.form.email = email;
    }
    if (ref) {
      this.ref = ref;
    }
    await this.loginWithToken()
    let controller = this.$Parse.CoreManager.getStorageController()
    console.log(controller)
    console.log(typeof controller )
  },
};
</script>
