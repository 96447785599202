<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid class="overflow-y-auto">
        <div class="flex flex-col mx-auto w-1/3">
          <p class="headline text-center grey--text mt-6">
            Welcome to <strong>EasyNotes</strong>
          </p>
          <v-card class="elevation-2">
            <p class="text-gray-400 font-bold text-center my-2 text-lg">{{ texts.title }}</p>
            <v-card-text>
              <div v-show="error" class="alert alert-danger" role="alert">
                <strong>{{ texts.errorLabel }}</strong> {{ error }}
              </div>
              <div v-show="success" class="alert alert-info" role="alert">
                <strong>{{ texts.successLabel }}</strong> {{ success }}
              </div>
              <div class="form-group">
                <label for="email">
                  {{ texts.info }}
                </label>
                <v-text-field
                  label="Email address"
                  class="mt-2"
                  clearable
                  rounded
                  filled
                  hide-details
                  v-model="email">
                </v-text-field>
              </div>
            </v-card-text>
            <v-card-actions>
              &nbsp;
              &nbsp;
              <router-link :to="{ path: '/login' }">
                {{ texts.loginLink }}
              </router-link>
              <v-spacer />
              <v-btn color="primary" @click.stop="onResetPassword">
                {{ texts.resetBtnText }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'ForgotPasswordPage',

  data() {
    return {
      error: null,
      success: null,
      email: ''
    }
  },

  computed: {
    texts() {
      return {
        title: 'Forgot Your Password?',
        errorLabel: 'Oh snap!',
        successLabel: 'Hoo-ray!',
        info: 'Please provide your email address, we will send you a link to reset your login password.',
        resetBtnText: 'Reset password',
        successMessage: 'Password reset mail has been sent to your address successfully, please check your inbox.',
        errorMessage: 'Failed to sent the email, please verify your email address',
        loginLink: 'Back to login'
      }
    }
  },

  methods: {
    async onResetPassword() {
      if (_.isNil(this.email)) {
        alert('Email address is required');
        return;
      }
      
      try {
        await this.$Parse.User.requestPasswordReset( this.email );
        this.stepFinished = true;
      } catch(error) {
        console.error(error);
        this.error = `Error while creating request to reset user password: ${JSON.stringify(error)}`;
      }
    }
  }
}
</script>

