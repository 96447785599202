<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid class="overflow-y-auto">
        <div class="flex flex-col mx-auto w-1/3">
          <p class="headline text-center grey--text mt-6">
            Welcome to <strong>EasyNotes</strong>!
          </p>
          <v-card class="elevation-2">
            <p class="text-gray-400 font-bold text-center my-2 text-lg">Sign Up Form</p>
            <v-card-text class="pb-0">
              <v-form class="flex flex-col space-y-2">
                <v-text-field
                  prepend-icon="mdi-account"
                  name="username"
                  label="Username"
                  type="text"
                  dense
                  clearable
                  rounded
                  filled
                  hide-details
                  v-model="form.username">
                </v-text-field>
                <v-text-field
                  prepend-icon="mdi-email"
                  name="email"
                  label="Email"
                  type="text"
                  dense
                  clearable
                  rounded
                  filled
                  hide-details
                  v-model="form.email">
                </v-text-field>
                <v-text-field
                  id="password"
                  prepend-icon="mdi-lock"
                  name="password"
                  label="Password"
                  type="password"
                  dense
                  clearable
                  rounded
                  filled
                  hide-details
                  v-model="form.password">
                </v-text-field>
                <v-text-field
                  id="password"
                  prepend-icon="mdi-lock-check"
                  name="password"
                  label="Retype Password"
                  dense
                  clearable
                  rounded
                  filled
                  hide-details
                  type="password"
                  v-model="form.password2">
                </v-text-field>
              </v-form>
            </v-card-text>
            <v-card-text class="py-0">
              <v-list-item dense class="ma-0 pa-0">
                <v-list-item-icon class="ma-0 pa-0">
                  <v-checkbox class="mx-0 my-2 pa-0"></v-checkbox>
                </v-list-item-icon>
                <v-list-item-title class="ma-0 pa-0">
                  Check to agree
                  <router-link :to="{ path: '/terms' }">Terms</router-link> and 
                  <router-link :to="{ path: '/privacy' }">Policy</router-link>
                </v-list-item-title>
              </v-list-item>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn block color="primary" @click="signup">Signup</v-btn>
            </v-card-actions>
            <v-card-text class="pt-0">
              Already a user?
              <router-link :to="{ path: '/login' }">Login</router-link>
            </v-card-text>
          </v-card>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'SignupPage',

  data() {
    return {
      form: {
        username: '',
        email: '',
        password: '',
        password2: ''
      },
      ref: null
    };
  },
  
  methods: {
    async signup() {
      const form = this.form;
      if (form.password !== form.password2) {
        this.$notify('Password not match');
        return;
      }

      try {
        let user = new this.$Parse.User()
        user.set('username', form.username)
        user.set('password', form.password)
        user.set('email', form.email)
        await user.signUp()
        if (this.ref) {
          this.$router.push( { path: this.ref });
        } else {
          this.$router.push( { path: '/' });
        }
      } catch(err) {
        this.$notify(err.message);
      }
    }
  },

  async mounted() {
    let { email, ref } = this.$route.query;
    if (email) {
      this.form.email = email;
    }
    if (ref) {
      this.ref = ref;
    }

    if (this.$api.isAuthenticated()) {
      await this.$Parse.User.logOut()
    }
  },
};
</script>
